import {errorHandlers} from './error-handlers/error-handlers';

export default {
  errorHandlers: {},

  /**
   * Install plugin
   */
  async install(app) {
    // If no error handlers are defined, return
    if (!Object.keys(errorHandlers).length) {
      return;
    }

    // Register error handlers
    Object.entries(errorHandlers).forEach(([name, handler]) => {
      this.errorHandlers[name] = new handler();
    });

    // Set error handler
    app.config.errorHandler = (...args) => {
      for (const errorHandler of Object.values(this.errorHandlers)) {
        // Process error
        errorHandler.processError(...args);
      }
    }
  }
}
